import React, { useEffect } from "react";
import { FileNode, NodeId } from "../state/types";
import { useAppDispatch, useAppSelector } from "../state/store";
import { nodeMapSelector } from "../state/selectors";
import { checkThumbnail, loadFile } from "../state/thunks";
import { getThumbnailKey } from "../util/imageDetails";
import { toggleNodeSelection } from "../state/gallery/slice";

export interface ImageCardProps {
    id: NodeId;
}

const ImageCard: React.FunctionComponent<ImageCardProps> = ({ id }) => {
    const dispatch = useAppDispatch();
    const nodeMap = useAppSelector(nodeMapSelector);
    const node = nodeMap[id];

    useEffect(() => {
        if (!node || node.type === "folder" || node.deleted) {
            return;
        }
        if (node.state === "idle") {
            const thumbnailKey = getThumbnailKey(node.objectKey!);
            dispatch(checkThumbnail({ id: node.id, objectKey: thumbnailKey }));
        } else if (node.state === "available") {
            dispatch(loadFile({ id: node.id, objectKey: node.objectKey! }));
        }
    }, [dispatch, node]);

    if (!node) {
        return <div>Error: unknown child {id}</div>;
    }

    if (node.type === "folder") {
        return null;
    } else if (!node.objectKey) {
        return <div>Error: expecting objectKey to be defined</div>;
    }

    if (node.deleted) {
        return null;
    }

    const thumbnail = getThumbnail(node);
    const className = node.selected ? "card selected" : "card";
    return (
        <span
            className={className}
            onClick={() => dispatch(toggleNodeSelection(node.id))}
        >
            {thumbnail}
        </span>
    );
};

function getThumbnail(node: FileNode) {
    switch (node.state) {
        case "idle":
        case "checking":
        case "available":
        case "loading":
            return <div>Loading...</div>;
        case "loaded":
            return <img alt={node.name} src={node.preSignedUrl} />;
        case "not_available":
            return <div>Thumbnail not available</div>;
        case "load_error":
            return <div>Error: {node.error}</div>;
    }
}

export default ImageCard;
