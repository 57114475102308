import { RootState } from "./store";

export function currentFolderSelector(state: RootState) {
    return state.gallery.currentFolder;
}

export function uploadQueueSelector(state: RootState) {
    return state.gallery.uploadQueue;
}

export function deleteQueueSelector(state: RootState) {
    return state.gallery.deleteQueue;
}

export function rootNodeSelector(state: RootState) {
    return state.gallery.rootNode;
}

export function nodeMapSelector(state: RootState) {
    return state.gallery.nodeMap;
}

export function uploadProgressSelector(state: RootState) {
    return state.gallery.uploadProgress;
}

export function selectionModeSelector(state: RootState) {
    return state.gallery.selectionMode;
}
