import { createAsyncThunk } from "@reduxjs/toolkit";
import { NodeId } from "../types";
import { CLIENT } from "../../util/client";

export interface AddFolderRequest {
    name: string;
    parent: NodeId;
}

export const addFolder = createAsyncThunk<NodeId, AddFolderRequest>(
    "folder/addFolder",
    async ({ name, parent }) => {
        const response = await CLIENT.addFolder(name, parent);
        return response.id;
    }
);
