import { getChildren } from "../thunks";
import { FolderNode, GalleryState } from "../types";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit";

export function addGetChildrenReducers(
    builder: ActionReducerMapBuilder<GalleryState>
) {
    builder.addCase(getChildren.pending, (state, action) => {
        const id = action.meta.arg;
        console.log(`getChildren for ${id} pending`);
        state.nodeMap[id].state = "loading";
    });
    builder.addCase(getChildren.fulfilled, (state, action) => {
        const id = action.meta.arg;
        console.log(`getChildren for ${id} resolved`);
        console.log(action.payload);
        const node = state.nodeMap[id] as FolderNode;
        node.state = "loaded";
        node.children = action.payload.map((child) => child.id);
        action.payload.forEach((child) => {
            if (child.type === "folder") {
                state.nodeMap[child.id] = {
                    type: "folder",
                    state: "idle",
                    id: child.id,
                    parent: id,
                    name: child.name,
                    selected: false,
                    deleted: false,
                    isOpen: false,
                };
            } else {
                state.nodeMap[child.id] = {
                    type: "file",
                    state: "idle",
                    id: child.id,
                    parent: id,
                    name: child.name,
                    selected: false,
                    deleted: false,
                    objectKey: child.objectKey!,
                };
            }
        });
    });
    builder.addCase(getChildren.rejected, (state, action) => {
        const id = action.meta.arg;
        console.log(`getChildren for ${id} rejected: ${action.error.message}`);
        state.nodeMap[id].state = "load_error";
        state.nodeMap[id].error = action.error.message;
    });
}
