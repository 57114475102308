import React, { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../state/store";
import { deleteQueueSelector, nodeMapSelector } from "../state/selectors";
import { deleteFile } from "../state/thunks";

const QueueDeleter: React.FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const nodeMap = useAppSelector(nodeMapSelector);
    const deleteQueue = useAppSelector(deleteQueueSelector);

    useEffect(() => {
        if (deleteQueue.length > 0) {
            const node = nodeMap[deleteQueue[0]];
            if (node.type === "folder") {
                dispatch(deleteFile({ id: node.id }));
            } else {
                dispatch(
                    deleteFile({ id: node.id, objectKey: node.objectKey })
                );
            }
        }
    }, [dispatch, nodeMap, deleteQueue]);

    if (deleteQueue.length === 0) {
        return null;
    }

    return <div>Deleting: {deleteQueue.length} remaining</div>;
};

export default QueueDeleter;
