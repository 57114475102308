import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../state/store";
import { uploadFile } from "../state/thunks";
import {
    uploadProgressSelector,
    uploadQueueSelector,
} from "../state/selectors";

const QueueUploader: React.FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const uploadQueue = useAppSelector(uploadQueueSelector);
    const uploadProgress = useAppSelector(uploadProgressSelector);

    useEffect(() => {
        if (uploadQueue.length !== 0) {
            const head = uploadQueue[0];
            if (head.state === "idle") {
                dispatch(uploadFile(head));
            }
        }
    }, [dispatch, uploadQueue]);

    if (uploadQueue.length === 0) {
        return null;
    }

    const head = uploadQueue[0];
    return (
        <div>
            Uploading {head.file.name}, {uploadQueue.length} left
            {uploadProgress ? (
                <span>
                    ({uploadProgress.loaded}/{uploadProgress.total})
                </span>
            ) : null}
        </div>
    );
};

export default QueueUploader;
