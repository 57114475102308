import React from "react";
import { useAppSelector } from "../state/store";
import { nodeMapSelector, rootNodeSelector } from "../state/selectors";
import FolderTreeNode from "./FolderTreeNode";
import { FolderNode } from "../state/types";

const ItemTree: React.FunctionComponent = () => {
    const rootNode = useAppSelector(rootNodeSelector);
    const nodeMap = useAppSelector(nodeMapSelector);

    if (rootNode.state === "load_error") {
        return <div>Cannot load root node: {rootNode.error}</div>;
    } else if (rootNode.state === "idle" || rootNode.state === "loading") {
        return <div>Loading...</div>;
    }

    const node = nodeMap[rootNode.id!] as FolderNode;

    return (
        <ul>
            <FolderTreeNode node={node} />
        </ul>
    );
};

export default ItemTree;
