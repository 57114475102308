import { Draft } from "@reduxjs/toolkit";
import { FolderNode, GalleryState, ItemNode, NodeId } from "../types";

export function updateMultiSelection(state: Draft<GalleryState>, id: string) {
    const node = state.nodeMap[id];
    const current = node.selected;
    if (node.type === "file") {
        propagateUp(state, id, !current);
    } else {
        propagateDown(state, id, !current);
        propagateUp(state, id, !current);
    }
}

export function updateSingleSelection(state: Draft<GalleryState>, id: string) {
    const node = state.nodeMap[id];
    const selectedNodes = getSelectedNodes(state.nodeMap, state.rootNode.id!);
    const current = node.selected;

    if (selectedNodes.length === 1 && selectedNodes[0] !== node.id) {
        const prevNode = state.nodeMap[selectedNodes[0]];
        prevNode.selected = false;
        if (
            prevNode.type === "folder" &&
            node.type === "folder" &&
            node.selected
        ) {
            state.currentFolder = undefined;
        }
    }

    node.selected = !current;
    if (node.selected) {
        if (node.type === "folder") {
            state.currentFolder = node.id;
        } else {
            state.currentFolder = node.parent;
        }
    }
}

export function deselectSubTree(state: Draft<GalleryState>, root: NodeId) {
    const node = state.nodeMap[root];
    node.selected = false;
    if (node.type === "file") {
        return;
    }
    (node as FolderNode).children?.forEach((child) => {
        deselectSubTree(state, child);
    });
}

export function getSelectedNodes(
    nodeMap: {
        [key: string]: ItemNode;
    },
    root: NodeId
): NodeId[] {
    const node = nodeMap[root];
    if (!node) {
        return [];
    }
    if (node.type === "folder") {
        const selectedNodes =
            (node as FolderNode).children?.flatMap((child) =>
                getSelectedNodes(nodeMap, child)
            ) || [];
        if (node.selected) {
            selectedNodes.push(node.id);
        }
        return selectedNodes;
    } else {
        return node.selected ? [node.id] : [];
    }
}

export function canSelectNode(
    nodeMap: { [key: string]: ItemNode },
    id: NodeId
): boolean {
    const node = nodeMap[id];
    if (node.type === "file") {
        return true;
    }

    // can only select if the children are known
    if (!node.children) {
        return false;
    }

    return node.children.reduce<boolean>(
        (acc, nodeId) => acc && canSelectNode(nodeMap, nodeId),
        true
    );
}

function propagateDown(
    state: Draft<GalleryState>,
    id: string,
    selected: boolean
) {
    const node = state.nodeMap[id];
    if (node.selected === selected) {
        return;
    }
    node.selected = selected;
    if (node.type === "file") {
        return;
    }

    const children = (node as FolderNode).children;
    children?.forEach((child) => {
        propagateDown(state, child, selected);
    });
}

function propagateUp(
    state: Draft<GalleryState>,
    id: string,
    selected: boolean
) {
    const node = state.nodeMap[id];
    const parent = node.parent;

    if (node.type === "file") {
        // unconditional selection update
        node.selected = selected;
        propagateUp(state, parent, selected);
    } else {
        const folderNode = node as FolderNode;
        if (!folderNode.children) {
            // cannot update if children are not known
            return;
        }
        const noChildren = folderNode.children.length === 0;
        const allSelected = folderNode.children.every((child) => {
            const childNode = state.nodeMap[child];
            return childNode.selected;
        });
        const someDeselected = folderNode.children.some((child) => {
            const childNode = state.nodeMap[child];
            return !childNode.selected;
        });

        // logic is different for selection / deselection
        if (
            (selected && allSelected) ||
            (!selected && (noChildren || someDeselected))
        ) {
            node.selected = selected;
            if (parent !== id) {
                propagateUp(state, parent, selected);
            }
        }
    }
}
