import React, { useEffect } from "react";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { useAppDispatch } from "../state/store";
import { getRoot } from "../state/thunks";
import NavBar from "./NavBar";
import FileManager from "./FileManager";
import QueueUploader from "./QueueUploader";
import ImageViewer from "./ImageViewer";

const App: React.FunctionComponent = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getRoot());
    }, [dispatch]);

    return (
        <>
            <NavBar />
            <FileManager />
            <ImageViewer />
            <QueueUploader />
        </>
    );
};

export default withAuthenticator(App);
