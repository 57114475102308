import { uploadFile } from "../thunks";
import { FolderNode, GalleryState } from "../types";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit";

export function addUploadFileReducers(
    builder: ActionReducerMapBuilder<GalleryState>
) {
    builder.addCase(uploadFile.pending, (state, action) => {
        console.log("uploadFile.pending");
        const { file: uploadFile } = action.meta.arg;
        const fileInfo = state.uploadQueue.filter(
            ({ file }) => file.name === uploadFile.name
        );
        if (fileInfo.length === 1) {
            fileInfo[0].state = "uploading";
        }
    });
    builder.addCase(uploadFile.fulfilled, (state, action) => {
        console.log("uploadFile.fulfilled");
        const { file: uploadFile } = action.meta.arg;
        state.uploadQueue = state.uploadQueue.filter(
            ({ file }) => file.name !== uploadFile.name
        );
        const { id, parent, name, objectKey } = action.payload;
        state.nodeMap[id] = {
            state: "idle",
            type: "file",
            id,
            parent,
            name,
            selected: false,
            deleted: false,
            objectKey,
        };

        const parentNode = state.nodeMap[parent] as FolderNode;
        if (parentNode && parentNode.children) {
            parentNode.children.push(id);
        } else {
            console.error(`unexpected state: parent ${parent}`);
        }
    });
    builder.addCase(uploadFile.rejected, (state, action) => {
        console.log(`uploadFile.rejected: ${action.error.message}`);
        const { file: uploadFile } = action.meta.arg;
        const fileInfo = state.uploadQueue.filter(
            ({ file }) => file.name === uploadFile.name
        );
        if (fileInfo.length === 1) {
            fileInfo[0].state = "upload_error";
            fileInfo[0].error = action.error.message;
        }
    });
}
