import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../state/store";
import { addFilesToUploadQueue } from "../state/gallery/slice";
import { FileInfo } from "../state/types";
import { currentFolderSelector } from "../state/selectors";

const UploadForm: React.FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const currentNodeId = useAppSelector(currentFolderSelector);
    const [isOpen, setOpen] = useState(false);
    const [canUpload, setCanUpload] = useState(false);
    const [files, setFiles] = useState<FileList | undefined>(undefined);

    if (currentNodeId === undefined) {
        return null;
    }

    function onChange(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.files && event.target.files.length > 0) {
            setCanUpload(true);
            setFiles(event.target.files);
        } else {
            setCanUpload(false);
        }
    }

    function uploadFiles() {
        if (currentNodeId && files) {
            const filesArray: FileInfo[] = Array.from(files).map((file) => ({
                state: "idle",
                parent: currentNodeId,
                file,
            }));
            dispatch(addFilesToUploadQueue(filesArray));
            setOpen(false);
        }
    }

    if (isOpen) {
        return (
            <>
                <label htmlFor={"uploadButton"}>Upload: </label>
                <input
                    type={"file"}
                    name={"uploadButton"}
                    multiple={true}
                    onChange={onChange}
                    accept={"image/jpeg"}
                />
                <button disabled={!canUpload} onClick={uploadFiles}>
                    Upload
                </button>
                <button onClick={() => setOpen(false)}>Cancel</button>
            </>
        );
    }
    return <button onClick={() => setOpen(true)}>Upload</button>;
};

export default UploadForm;
