import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { addFolder } from "./thunk";
import { FolderState, FolderAddState } from "./types";

const INITIAL_FOLDER_STATE: FolderState = {
    state: "idle",
};

const addFolderSlice = createSlice({
    name: "addFolder",
    initialState: INITIAL_FOLDER_STATE,
    reducers: {
        setAddFolderState(
            state: Draft<FolderState>,
            action: PayloadAction<FolderAddState>
        ) {
            state.state = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(addFolder.pending, (state, action) => {
            state.state = "adding";
        });
        builder.addCase(addFolder.fulfilled, (state, action) => {
            state.state = "added";
            state.id = action.payload;
        });
        builder.addCase(addFolder.rejected, (state, action) => {
            state.state = "add_error";
            state.error = action.error.message;
        });
    },
});

export const { setAddFolderState } = addFolderSlice.actions;
export default addFolderSlice.reducer;
