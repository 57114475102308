import { loadFile } from "../thunks";
import { FileNode, GalleryState } from "../types";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit";

export function addLoadFileReducers(
    builder: ActionReducerMapBuilder<GalleryState>
) {
    builder.addCase(loadFile.pending, (state, action) => {
        const { id } = action.meta.arg;
        state.nodeMap[id].state = "loading";
    });
    builder.addCase(loadFile.fulfilled, (state, action) => {
        const { id } = action.meta.arg;
        const node = state.nodeMap[id] as FileNode;
        node.state = "loaded";
        node.preSignedUrl = action.payload;
    });
    builder.addCase(loadFile.rejected, (state, action) => {
        const { id } = action.meta.arg;
        state.nodeMap[id].state = "load_error";
        state.nodeMap[id].error = "Cannot load image";
    });
}
