import { createAsyncThunk } from "@reduxjs/toolkit";
import { Storage } from "aws-amplify";
import { Item, CLIENT } from "../util/client";
import { FileInfo, NodeId } from "./types";
import { v4 as uuidv4 } from "uuid";
import { getThumbnailKey } from "../util/imageDetails";
import { updateUploadProgress } from "./gallery/slice";

export interface UploadFileResult {
    parent: NodeId;
    id: NodeId;
    name: string;
    objectKey: string;
}

export interface UploadProgress {
    loaded: number;
    total: number;
}

export interface LoadFileRequest {
    id: NodeId;
    objectKey: string;
}

export interface DeleteFileRequest {
    id: NodeId;
    objectKey?: string;
}

const sleep = (milliseconds: number) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

export const uploadFile = createAsyncThunk<UploadFileResult, FileInfo>(
    "gallery/uploadFile",
    async ({ file, parent }, { dispatch }) => {
        console.log(`uploading ${file.name}`);
        const uuid = uuidv4();
        const objectKey = `photos/${uuid}/${file.name}`;
        const content = await file.arrayBuffer();
        console.log(`using objectKey '${objectKey}'`);

        await Storage.put(objectKey, content, {
            level: "private",
            serverSideEncryption: "AES256",
            progressCallback(progress: ProgressEvent) {
                dispatch(
                    updateUploadProgress({
                        loaded: progress.loaded,
                        total: progress.total,
                    })
                );
            },
        });
        const response = await CLIENT.addFile(file.name, parent, objectKey);

        return {
            parent,
            name: file.name,
            id: response.id,
            objectKey,
        };
    }
);

export const checkThumbnail = createAsyncThunk<boolean, LoadFileRequest>(
    "gallery/checkFile",
    async ({ objectKey }) => {
        for (let i = 0; i < 5; i++) {
            const response = await Storage.list(objectKey, {
                level: "private",
            });
            if (response.length !== 0) {
                return true;
            }
            await sleep(1000);
        }
        return false;
    }
);

export const getRoot = createAsyncThunk<string, void>(
    "gallery/getRoot",
    async () => {
        const response = await CLIENT.getRoot();
        return response.id;
    }
);

export const getChildren = createAsyncThunk<Item[], NodeId>(
    "gallery/getChildren",
    async (id) => {
        const response = await CLIENT.getChildren(id);
        return response.items;
    }
);

export const loadFile = createAsyncThunk<string, LoadFileRequest>(
    "gallery/loadFile",
    async ({ objectKey }) => {
        const thumbnailKey = getThumbnailKey(objectKey);
        return (await Storage.get(thumbnailKey, {
            level: "private",
        })) as string;
    }
);

export const deleteFile = createAsyncThunk<void, DeleteFileRequest>(
    "gallery/deleteSelected",
    async ({ id, objectKey }) => {
        await CLIENT.deleteFile(id);
        if (objectKey) {
            await Storage.remove(objectKey, { level: "private" });
        }
    }
);
