import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { FolderNode, GalleryState } from "../types";
import { deleteFile } from "../thunks";

export function addDeleteFileReducers(
    builder: ActionReducerMapBuilder<GalleryState>
) {
    builder.addCase(deleteFile.pending, (state, action) => {
        const { id } = action.meta.arg;
        console.log(`deleting ${id}`);
        const node = state.nodeMap[id];
        node.deleted = true;
    });

    builder.addCase(deleteFile.fulfilled, (state, action) => {
        const { id } = action.meta.arg;
        console.log(`deleted ${id}`);
        state.deleteQueue = state.deleteQueue.filter(
            (queueId) => queueId !== id
        );
        const node = state.nodeMap[id];
        // sanity check
        if (
            node.type === "folder" &&
            node.children &&
            node.children.length > 0
        ) {
            console.error("Deleting node with children");
            return;
        }
        // remove node from parent
        const parentId = node.parent;
        const parent = state.nodeMap[parentId] as FolderNode;
        parent.children = parent.children!.filter((childId) => childId !== id);
        // delete node from map
        delete state.nodeMap[id];
    });

    builder.addCase(deleteFile.rejected, (state, action) => {
        const { id } = action.meta.arg;
        console.log(`cannot delete ${id}: ${action.error.message}`);
        state.deleteQueue = state.deleteQueue.filter(
            (queueId) => queueId !== id
        );
        const node = state.nodeMap[id];
        node.deleted = false;
        node.error = action.error.message;
    });
}
