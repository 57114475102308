import { Auth } from "aws-amplify";
import axios from "axios";
import { NodeId, NodeType } from "../state/types";

const BASE_URL = "https://gallery.pilkrow.com/api";

export interface GetRootResponse {
    id: NodeId;
}

export interface Item {
    id: NodeId;
    type: NodeType;
    name: string;
    objectKey?: string;
}

export interface GetChildrenResponse {
    items: Item[];
}

export interface AddFileResponse {
    id: NodeId;
}

export interface AddFolderResponse {
    id: NodeId;
}

export class Client {
    public async getRoot(): Promise<GetRootResponse> {
        return Client.call<GetRootResponse>("get-root");
    }

    public async getChildren(id: string): Promise<GetChildrenResponse> {
        return Client.call<GetChildrenResponse>("get-children", { parent: id });
    }

    public async addFile(
        name: string,
        parent: NodeId,
        objectKey: string
    ): Promise<AddFileResponse> {
        return Client.call<AddFileResponse>("add-file", {
            name,
            parent,
            objectKey,
        });
    }

    public async deleteFile(id: NodeId): Promise<void> {
        return Client.call<void>("delete-file", {
            id,
        });
    }

    public async addFolder(
        name: string,
        parent: NodeId
    ): Promise<AddFolderResponse> {
        return Client.call<AddFolderResponse>("add-folder", {
            parent,
            name,
        });
    }

    private static async call<T>(api: string, request: any = null): Promise<T> {
        const session = await Auth.currentSession();
        if (session.isValid()) {
            const response = await axios.post(
                `${BASE_URL}/${api}`,
                JSON.stringify(request),
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${session
                            .getIdToken()
                            .getJwtToken()}`,
                    },
                }
            );
            return response.data;
        }
        throw new Error("Invalid session");
    }
}

export const CLIENT = new Client();
