import React from "react";
import { toggleSelectionMode } from "../state/gallery/slice";
import { useAppDispatch, useAppSelector } from "../state/store";
import { selectionModeSelector } from "../state/selectors";

const SelectFilesControl: React.FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const selectionMode = useAppSelector(selectionModeSelector);

    return (
        <div>
            <label htmlFor={"selectionMode"}>Select files: </label>
            <input
                name={"selectionMode"}
                type={"checkbox"}
                checked={selectionMode === "multi"}
                onChange={() => dispatch(toggleSelectionMode())}
            />
        </div>
    );
};

export default SelectFilesControl;
