import React from "react";
import ItemTree from "./ItemTree";
import UploadForm from "./UploadForm";
import SelectFilesControl from "./SelectFilesControl";
import DeleteControl from "./DeleteControl";
import AddFolderControl from "./AddFolderControl";

const FileManager: React.FunctionComponent = () => {
    return (
        <>
            <UploadForm />
            <SelectFilesControl />
            <DeleteControl />
            <AddFolderControl />
            <ItemTree />
        </>
    );
};

export default FileManager;
