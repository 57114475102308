import React from "react";
import CurrentFolder from "./CurrentFolder";

const NavBar: React.FunctionComponent = () => {
    return (
        <>
            <h1>Image Gallery</h1>
            <CurrentFolder />
        </>
    );
};

export default NavBar;
