import React from "react";
import ReactDOM from "react-dom";
import { Amplify } from "aws-amplify";
import App from "./components/App";
import { Provider } from "react-redux";
import { store } from "./state/store";
import "./styles.css";

Amplify.configure({
    Auth: {
        identityPoolId: "eu-west-1:2cf2d5a3-c113-4ffb-b1c4-71c724d67685",
        region: "eu-west-1",
        userPoolId: "eu-west-1_ANOR2fycE",
        userPoolWebClientId: "5fr9t1rlp5ff0h3ot0j2636aao",
    },
    Storage: {
        AWSS3: {
            bucket: "ixorainfrastage-ixorainf-imagestorestoragebucket8-emn08i1x2jwx",
            region: "eu-west-1",
        },
    },
});

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById("root")
);
