import { checkThumbnail } from "../thunks";
import { FileNode, GalleryState } from "../types";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit";

export function addCheckThumbnailReducers(
    builder: ActionReducerMapBuilder<GalleryState>
) {
    builder.addCase(checkThumbnail.pending, (state, action) => {
        const { id } = action.meta.arg;
        const node = state.nodeMap[id] as FileNode;
        node.state = "checking";
    });
    builder.addCase(checkThumbnail.fulfilled, (state, action) => {
        const { id } = action.meta.arg;
        const node = state.nodeMap[id] as FileNode;
        if (action.payload) {
            node.state = "available";
        } else {
            node.state = "not_available";
        }
    });
    builder.addCase(checkThumbnail.rejected, (state, action) => {
        const { id } = action.meta.arg;
        const node = state.nodeMap[id] as FileNode;
        node.state = "not_available";
        console.error(
            `Cannot check thumbnail for ${id}: ${action.error.message}`
        );
    });
}
