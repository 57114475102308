import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../state/store";
import { getChildren } from "../state/thunks";
import FileTreeNode from "./FileTreeNode";
import { FolderNode, ItemNode, NodeId } from "../state/types";
import {
    toggleIsOpen,
    toggleNodeSelection,
    updateCurrentFolder,
} from "../state/gallery/slice";
import {
    currentFolderSelector,
    nodeMapSelector,
    selectionModeSelector,
} from "../state/selectors";
import { canSelectNode } from "../state/logic/selection";

export interface FolderTreeNodeProps {
    node: FolderNode;
}

const FolderTreeNode: React.FunctionComponent<FolderTreeNodeProps> = ({
    node,
}) => {
    const dispatch = useAppDispatch();
    const nodeMap = useAppSelector(nodeMapSelector);
    const selectionMode = useAppSelector(selectionModeSelector);
    const currentFolder = useAppSelector(currentFolderSelector);

    useEffect(() => {
        if (node.isOpen && node.state === "idle") {
            dispatch(getChildren(node.id));
        }
    }, [dispatch, node]);

    if (node.deleted) {
        return null;
    }

    const canSelect = canSelectNode(nodeMap, node.id);

    function onSelect() {
        dispatch(updateCurrentFolder(node.id));
        if (!node.children) {
            dispatch(getChildren(node.id));
        }
        if (canSelect) {
            dispatch(toggleNodeSelection(node.id));
        }
    }

    function toggleOpen() {
        dispatch(toggleIsOpen(node.id));
    }

    const isCurrentNode = currentFolder === node.id;

    let className = "folder";
    if (node.selected && selectionMode === "single") {
        className += " selected";
    }
    if (isCurrentNode) {
        className += " current";
    }

    const name = node.name || "/";
    const selection =
        selectionMode === "multi" ? (
            <input
                type={"checkbox"}
                checked={node.selected}
                onClick={onSelect}
                readOnly={true}
                disabled={!canSelect}
            />
        ) : null;
    const folderTitle = (
        <span>
            {selection}
            <span onClick={toggleOpen}>{node.isOpen ? "[-] " : "[+] "}</span>
            <span className={className} onClick={onSelect}>
                {name} ({node.type})
            </span>
        </span>
    );
    return (
        <li>
            {folderTitle}
            {node.isOpen ? (
                <ul>
                    {node.children?.map((childId) =>
                        getChildNode(nodeMap, childId)
                    )}
                </ul>
            ) : null}
        </li>
    );
};

function getChildNode(nodeMap: { [key: string]: ItemNode }, childId: NodeId) {
    const child = nodeMap[childId];
    if (child.type === "file") {
        return <FileTreeNode key={childId} node={child} />;
    } else {
        return <FolderTreeNode key={childId} node={child} />;
    }
}

export default FolderTreeNode;
