import React from "react";
import { FileNode } from "../state/types";
import {
    toggleNodeSelection,
    updateCurrentFolder,
} from "../state/gallery/slice";
import { useAppDispatch, useAppSelector } from "../state/store";
import { selectionModeSelector } from "../state/selectors";

export interface FileTreeNodeProps {
    node: FileNode;
}

const FileTreeNode: React.FunctionComponent<FileTreeNodeProps> = ({ node }) => {
    const dispatch = useAppDispatch();
    const selectionMode = useAppSelector(selectionModeSelector);

    if (node.deleted) {
        return null;
    }

    function onSelect() {
        dispatch(updateCurrentFolder(node.parent));
        dispatch(toggleNodeSelection(node.id));
    }

    const className =
        selectionMode === "single" && node.selected ? "file selected" : "file";
    return (
        <li>
            <span>
                {selectionMode === "multi" ? (
                    <input
                        type={"checkbox"}
                        checked={node.selected}
                        onChange={onSelect}
                    />
                ) : null}
                <span className={className} onClick={onSelect}>
                    {node.name} ({node.type})
                </span>
            </span>
        </li>
    );
};

export default FileTreeNode;
