import { getRoot } from "../thunks";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { GalleryState } from "../types";

export function addGetRootReducers(
    builder: ActionReducerMapBuilder<GalleryState>
) {
    builder.addCase(getRoot.pending, (state) => {
        state.rootNode.state = "loading";
    });
    builder.addCase(getRoot.fulfilled, (state, action) => {
        state.rootNode.state = "loaded";
        state.rootNode.id = action.payload;
        state.nodeMap[action.payload] = {
            state: "idle",
            type: "folder",
            id: action.payload,
            parent: action.payload, // root is its own parent
            name: "",
            selected: false,
            deleted: false,
            isOpen: true,
        };
        state.currentFolder = state.rootNode.id;
    });
    builder.addCase(getRoot.rejected, (state, action) => {
        state.rootNode.state = "load_error";
        state.rootNode.error = action.error.message;
    });
}
