import React from "react";
import { useAppSelector } from "../state/store";
import {
    currentFolderSelector,
    nodeMapSelector,
    rootNodeSelector,
} from "../state/selectors";

const CurrentFolder: React.FunctionComponent = () => {
    const currentNodeId = useAppSelector(currentFolderSelector);
    const rootNode = useAppSelector(rootNodeSelector);
    const nodeMap = useAppSelector(nodeMapSelector);

    let current = currentNodeId;
    if (current === undefined) {
        return null;
    }

    const path = [];
    do {
        const node = nodeMap[current];
        path.push(node.name || "/");
        current = nodeMap[current].parent;
    } while (current !== rootNode.id);

    const fullPath = path.reverse().join("/");

    return <div>Current path: {fullPath}</div>;
};

export default CurrentFolder;
