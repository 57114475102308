import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    FileInfo,
    FolderNode,
    GalleryState,
    NodeId,
    SelectionMode,
} from "../types";
import { UploadProgress } from "../thunks";
import {
    deselectSubTree,
    updateMultiSelection,
    updateSingleSelection,
} from "../logic/selection";
import { addDeleteFileReducers } from "../logic/deleteFile";
import { addUploadFileReducers } from "../logic/uploadFile";
import { addGetRootReducers } from "../logic/getRoot";
import { addGetChildrenReducers } from "../logic/getChildren";
import { addLoadFileReducers } from "../logic/loadFile";
import { addCheckThumbnailReducers } from "../logic/checkThumbnail";
import { addFolder } from "../folder/thunk";

const INITIAL_GALLERY_STATE: GalleryState = {
    rootNode: {
        state: "idle",
    },
    nodeMap: {},
    selectionMode: "single",
    uploadQueue: [],
    deleteQueue: [],
};

const slice = createSlice({
    name: "gallery",
    initialState: INITIAL_GALLERY_STATE,
    reducers: {
        updateCurrentFolder: (state, action: PayloadAction<string>) => {
            const node = state.nodeMap[action.payload];
            if (node.type === "folder") {
                state.currentFolder = node.id;
            } else {
                state.currentFolder = node.parent;
            }
        },
        toggleNodeSelection: (state, action: PayloadAction<string>) => {
            if (state.selectionMode === "multi") {
                updateMultiSelection(state, action.payload);
            } else {
                updateSingleSelection(state, action.payload);
            }
        },
        addFilesToUploadQueue: (state, action: PayloadAction<FileInfo[]>) => {
            state.uploadQueue = state.uploadQueue.concat(action.payload);
        },
        addFilesToDeletionQueue: (state, action: PayloadAction<NodeId[]>) => {
            const withoutRoot = action.payload.filter(
                (id) => id !== state.rootNode.id
            );
            state.deleteQueue = state.deleteQueue.concat(withoutRoot);
            withoutRoot.map((id) => {
                const node = state.nodeMap[id];
                node.deleted = true;
            });
        },
        updateUploadProgress: (
            state,
            action: PayloadAction<UploadProgress>
        ) => {
            state.uploadProgress = action.payload;
        },
        setSelectionMode: (state, action: PayloadAction<SelectionMode>) => {
            deselectSubTree(state, state.rootNode.id!);
            state.selectionMode = action.payload;
        },
        toggleSelectionMode: (state) => {
            deselectSubTree(state, state.rootNode.id!);
            state.selectionMode =
                state.selectionMode === "single" ? "multi" : "single";
        },
        deselectAll: (state) => {
            deselectSubTree(state, state.rootNode.id!);
        },
        toggleIsOpen: (state, action: PayloadAction<NodeId>) => {
            const node = state.nodeMap[action.payload];
            if (node.type !== "folder") {
                return;
            }
            const folderNode = node as FolderNode;
            folderNode.isOpen = !folderNode.isOpen;
        },
    },
    extraReducers: (builder) => {
        addUploadFileReducers(builder);
        addGetRootReducers(builder);
        addGetChildrenReducers(builder);
        addLoadFileReducers(builder);
        addCheckThumbnailReducers(builder);
        addDeleteFileReducers(builder);

        builder.addCase(addFolder.fulfilled, (state, action) => {
            const { name, parent } = action.meta.arg;
            const id = action.payload;
            state.nodeMap[id] = {
                type: "folder",
                name,
                parent,
                id,
                state: "idle",
                children: [],
                deleted: false,
                isOpen: false,
                selected: false,
            };
            const parentNode = state.nodeMap[parent] as FolderNode;
            if (parentNode.children) {
                parentNode.children.push(id);
            }
        });
    },
});

export const {
    updateCurrentFolder,
    toggleNodeSelection,
    deselectAll,
    addFilesToUploadQueue,
    addFilesToDeletionQueue,
    updateUploadProgress,
    setSelectionMode,
    toggleSelectionMode,
    toggleIsOpen,
} = slice.actions;
export default slice.reducer;
