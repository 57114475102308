import React from "react";
import { useAppSelector } from "../state/store";
import { currentFolderSelector, nodeMapSelector } from "../state/selectors";
import ImageCard from "./ImageCard";
import { FolderNode } from "../state/types";

const ImageViewer: React.FunctionComponent = () => {
    const currentNodeId = useAppSelector(currentFolderSelector);
    const nodeMap = useAppSelector(nodeMapSelector);

    if (!currentNodeId) {
        return null;
    }

    const currentNode = nodeMap[currentNodeId] as FolderNode;
    if (currentNode.state === "loaded") {
        return (
            <div>
                {currentNode.children!.map((child) => (
                    <ImageCard key={child} id={child} />
                ))}
            </div>
        );
    } else if (currentNode.state === "load_error") {
        return <div>Error: {currentNode.error}</div>;
    } else {
        return <div>Loading...</div>;
    }
};

export default ImageViewer;
