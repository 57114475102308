import React, { ChangeEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../state/store";
import { currentFolderSelector } from "../state/selectors";
import { addFolder } from "../state/folder/thunk";
import { setAddFolderState } from "../state/folder/slice";

const AddFolderControl: React.FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const folder = useAppSelector((state) => state.folder);
    const currentFolder = useAppSelector(currentFolderSelector);
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [folderName, setFolderName] = useState<string>("");
    const [error, setError] = useState<string>("");

    useEffect(() => {
        if (folder.state === "added") {
            setFolderName("");
            setError("");
            setShowDialog(false);
            dispatch(setAddFolderState("idle"));
        }
    }, [dispatch, folder]);

    function onAddFolder() {
        if (!showDialog) {
            setShowDialog(true);
        }
    }

    function validateFolderName(folderName: string): string {
        if (folderName.match("/")) {
            return "Folder name must not include a forward slash";
        }
        return "";
    }

    function onFolderNameChange(e: ChangeEvent<HTMLInputElement>) {
        setFolderName(e.target.value);
        setError(validateFolderName(e.target.value));
    }

    function onConfirm() {
        dispatch(addFolder({ name: folderName, parent: currentFolder! }));
    }

    function onCancel() {
        setFolderName("");
        setError("");
        setShowDialog(false);
    }

    if (!currentFolder) {
        return null;
    }

    const dialog = (
        <div>
            <label htmlFor={"folderName"}>Folder Name: </label>
            <input
                type={"text"}
                name={"folderName"}
                value={folderName}
                onChange={onFolderNameChange}
            />
            <button
                type={"button"}
                disabled={
                    folderName.length === 0 ||
                    error.length !== 0 ||
                    folder.state === "adding"
                }
                onClick={onConfirm}
            >
                Confirm
            </button>
            <button type={"button"} onClick={onCancel}>
                Cancel
            </button>
            {error ? <div>{error}</div> : null}
        </div>
    );

    return (
        <div>
            <button type={"button"} disabled={showDialog} onClick={onAddFolder}>
                Add Folder
            </button>
            {showDialog ? dialog : null}
            {folder.error ? <div>Error: {folder.error}</div> : null}
        </div>
    );
};

export default AddFolderControl;
