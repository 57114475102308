import React, { useMemo, useState } from "react";
import {
    addFilesToDeletionQueue,
    deselectAll,
    setSelectionMode,
} from "../state/gallery/slice";
import { getSelectedNodes } from "../state/logic/selection";
import { useAppDispatch, useAppSelector } from "../state/store";
import { nodeMapSelector, rootNodeSelector } from "../state/selectors";
import { NodeId } from "../state/types";
import QueueDeleter from "./QueueDeleter";

type DeletionState = "idle" | "initiated" | "checked" | "confirmed";

const DeleteControl: React.FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const nodeMap = useAppSelector(nodeMapSelector);
    const rootNode = useAppSelector(rootNodeSelector);

    const [deletionState, setDeletionState] = useState<DeletionState>("idle");

    const selectedNodes = useMemo(
        () => getSelectedNodes(nodeMap, rootNode.id!),
        [nodeMap, rootNode.id]
    );
    if (selectedNodes.length === 0 && deletionState !== "idle") {
        setDeletionState("idle");
    }

    function isFile(id: NodeId) {
        return nodeMap[id].type === "file";
    }
    const fileCount = selectedNodes.filter(isFile).length;
    const folderCount = selectedNodes.length - fileCount;
    const message = getMessage(fileCount, folderCount);

    console.log(`selectedNodes: ${selectedNodes}`);

    function confirmDeletion() {
        setDeletionState("confirmed");
        dispatch(setSelectionMode("single"));
        dispatch(deselectAll());
        dispatch(addFilesToDeletionQueue(selectedNodes));
    }

    return (
        <div>
            <button
                type={"button"}
                disabled={selectedNodes.length === 0}
                onClick={() => setDeletionState("initiated")}
            >
                Delete
            </button>
            {deletionState === "initiated" ? (
                <div>
                    <div>{message}</div>
                    <button type={"button"} onClick={confirmDeletion}>
                        Confirm
                    </button>
                    <button
                        type={"button"}
                        onClick={() => setDeletionState("idle")}
                    >
                        Cancel
                    </button>
                </div>
            ) : null}
            <QueueDeleter />
        </div>
    );
};

function getMessage(fileCount: number, folderCount: number) {
    return (
        "You are about to delete " +
        (fileCount === 1
            ? "1 file"
            : fileCount > 0
            ? `${fileCount} files`
            : "") +
        (fileCount > 0 && folderCount > 0 ? " and " : "") +
        (folderCount > 0 ? `${folderCount} folders` : "") +
        ". Are you sure? "
    );
}

export default DeleteControl;
